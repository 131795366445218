import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'orderBy',
  pure: false
})
export class OrderByPipe implements PipeTransform {
  transform(array: any[], field: string, direction: 'asc' | 'desc' = 'asc'): any[] {
    // Return early if array is empty or field is not provided
    if (!array || !array.length || !field) {
      return array;
    }

    // Create a shallow copy to avoid modifying the original array
    const sortedArray = [...array];

    sortedArray.sort((a, b) => {
      // Handle null/undefined values
      const aValue = this.getPropertyValue(a, field);
      const bValue = this.getPropertyValue(b, field);
      
      // Handle cases where either value is null or undefined
      if (aValue === null || aValue === undefined) {
        return direction === 'asc' ? -1 : 1;
      }
      if (bValue === null || bValue === undefined) {
        return direction === 'asc' ? 1 : -1;
      }
      
      // Handle string comparisons
      if (typeof aValue === 'string' && typeof bValue === 'string') {
        return direction === 'asc' 
          ? aValue.localeCompare(bValue) 
          : bValue.localeCompare(aValue);
      } 
      
      // Handle number and other comparisons
      return direction === 'asc'
        ? (aValue < bValue ? -1 : (aValue > bValue ? 1 : 0))
        : (bValue < aValue ? -1 : (bValue > aValue ? 1 : 0));
    });

    return sortedArray;
  }

  // Helper method to access nested properties using dot notation (e.g., 'user.name')
  private getPropertyValue(object: any, path: string): any {
    if (!object || !path) {
      return null;
    }
    
    const properties = path.split('.');
    let value = object;

    for (const prop of properties) {
      if (value === null || value === undefined) {
        return null;
      }
      value = value[prop];
    }

    return value;
  }
}