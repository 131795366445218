import { NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { ToastrModule } from 'ngx-toastr';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxDatatableModule } from '@atomickit/ngx-datatable';

import { SidebarComponent } from '@shared/components/sidebar/sidebar.component';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { NavBarComponent } from './components/navbar/nav-bar.component';
import { BlankComponent } from '../layouts/blank/blank.component';
import { SpinnerComponent } from './spinner.component';
import { AddressListFormComponent } from '@shared/components/address-list/address-list-form.component';
import { AmountComponent } from '@app/shared/components/amount/amount.component';
import { StateTaxCardComponent } from './components/state-tax-card/state-tax-card.component';
import { MonthPickerComponent } from './components/month-picker/month-picker.component';
import { PercentTaxPipe } from '@app/pipes/percent-tax/percent-tax.pipe';
import { CentConversionPipe } from '@app/pipes/cent-conversion/cent-conversion.pipe';
import { TaxTableComponent } from './components/tax-table/tax-table.component';
import { TransactionDetailComponent } from './components/transaction-detail/transaction-detail.component';
import { RedirectComponent } from './components/redirect/redirect.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { DateRangePickerComponent } from './components/date-range-picker/date-range-picker.component';
import { DownloadFileDirective } from './directives/download-csv.directive';
import { StepperComponent } from '@shared/components/stepper/stepper.component';
import { InsightsBoardComponent } from '@shared/components/insights-board/insights-board.component';
import { WelcomeBoardComponent } from '@shared/components/welcome-board/welcome-board.component';
import { FullComponent } from '@app/layouts/full/full.component';
import { NavigatorComponent } from './components/navigator/navigator.component';
import { DemoComponent } from '@app/layouts/demo/demo.component';
import { PaymentSchedulePipe } from '@app/pipes/payment-schedule/payment-schedule.pipe';
import { ExemptionTypePipe } from '@app/pipes/exemption-type/exemption-type.pipe';
import { StringToKebapPipe } from '@app/pipes/string-to-kebap/string-to-kebap.pipe';
import { StateCodeToNamePipe } from '@app/pipes/state-code-to-name/state-code-to-name.pipe';
import { AmountPipe } from '@app/pipes/amount/amount.pipe';
import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';
import { SubscriptionIntervalPipe } from '@app/pipes/subscription-interval/subscription-interval.pipe';
import { IsoDatePipe } from '@app/pipes/isoDate/iso-date.pipe';
import { GoBackDirective } from './directives/go-back.directive';
import { PaymentComponent } from './components/payment/payment.component';
import { FeedbackComponent } from './components/feedback/feedback.component';
import { NewCardFormComponent } from './components/payment/new-card-form/new-card-form.component';
import { StateCardComponent } from '@shared/components/state-card/state-card.component';
import { CodeInputModule } from 'angular-code-input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { FrequencyTransformPipe } from '@app/pipes/frequency-transform/frequency-transform.pipe';
import { SnakeToStringPipe } from '@app/pipes/snake-to-string/snake-to-string.pipe';
import { PercentagePipe } from '@app/pipes/percentage/percentage.pipe';
import { StateStatusColorPipe } from '@app/pipes/state-status-color/state-status-color.pipe';
import { FilterFrequencyByPricesPipe } from '@app/pipes/filter-frequency-by-prices/filter-frequency-by-prices.pipe';
import { FilterAmountByPricesPipe } from '@app/pipes/filter-amount-by-prices/filter-amount-by-prices.pipe';
import { MatMenuModule } from '@angular/material/menu';
import { InformationBannersComponent } from './components/information-banners/information-banners.component';
import { MatRippleModule } from '@angular/material/core';
import { LinkedProviderProcessingComponent } from './components/linked-provider-processing/linked-provider-processing.component';
import { CalendlyComponent } from './components/calendly/calendly.component';
import { TextTruncatePipe } from '@app/pipes/text-truncate/text-truncate.pipe';
import { DownloadComponent } from './components/download/download.component';
import { StateFilterDropdownComponent } from './components/state-filter-dropdown/state-filter-dropdown.component';
import { OrderByPipe } from '@app/pipes/order-by.pipe';

@NgModule({
  declarations: [
    // Components...
    SpinnerComponent,
    BlankComponent,
    FullComponent,
    DemoComponent,
    NavBarComponent,
    BreadcrumbComponent,
    SidebarComponent,
    AddressListFormComponent,
    AmountComponent,
    StateTaxCardComponent,
    MonthPickerComponent,
    DateRangePickerComponent,
    StepperComponent,
    InsightsBoardComponent,
    WelcomeBoardComponent,
    TaxTableComponent,
    TransactionDetailComponent,
    RedirectComponent,
    DateRangePickerComponent,
    NavigatorComponent,
    StateCardComponent,
    LinkedProviderProcessingComponent,
    CalendlyComponent,
    StateFilterDropdownComponent,

    // Pipes...
    PercentTaxPipe,
    CentConversionPipe,
    PaymentSchedulePipe,
    ExemptionTypePipe,
    StringToKebapPipe,
    StateCodeToNamePipe,
    AmountPipe,
    SubscriptionIntervalPipe,
    IsoDatePipe,
    FrequencyTransformPipe,
    SnakeToStringPipe,
    PercentagePipe,
    StateStatusColorPipe,
    FilterFrequencyByPricesPipe,
    FilterAmountByPricesPipe,
    TextTruncatePipe,
    OrderByPipe,

    //Directives...
    DownloadFileDirective,
    ConfirmationDialogComponent,
    GoBackDirective,
    PaymentComponent,
    FeedbackComponent,
    NewCardFormComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    NgSelectModule,
    NgbModule,
    NgxDatatableModule,
    MatSelectModule,
    MatMenuModule,
    FormsModule,
    ReactiveFormsModule,
    NgScrollbarModule,
    TranslateModule,
    ToastrModule.forRoot({
      positionClass: 'toast-top-center',
      timeOut: 6000,
      easeTime: 600
    }),
    NgxSkeletonLoaderModule,
    NgOptimizedImage,
    CodeInputModule,
    MatRippleModule,
    InformationBannersComponent,
    DownloadComponent
  ],
  exports: [
    FormsModule,
    ToastrModule,
    NgSelectModule,
    NgbModule,
    MatFormFieldModule,
    MatSelectModule,
    MatMenuModule,
    ReactiveFormsModule,
    NgxDatatableModule,
    NgxSkeletonLoaderModule,
    CodeInputModule,
    MatRippleModule,
    TextTruncatePipe,

    // Components...
    SpinnerComponent,
    BlankComponent,
    FullComponent,
    NavBarComponent,
    BreadcrumbComponent,
    SidebarComponent,
    AddressListFormComponent,
    AmountComponent,
    StateTaxCardComponent,
    MonthPickerComponent,
    DateRangePickerComponent,
    InsightsBoardComponent,
    WelcomeBoardComponent,
    NavigatorComponent,
    TaxTableComponent,
    StepperComponent,
    PaymentComponent,
    FeedbackComponent,
    StateCardComponent,   
    InformationBannersComponent, 
    CalendlyComponent,
    StateFilterDropdownComponent,

    // Pipes...
    PercentTaxPipe,
    CentConversionPipe,
    PaymentSchedulePipe,
    ExemptionTypePipe,
    StringToKebapPipe,
    StateCodeToNamePipe,
    AmountPipe,
    SubscriptionIntervalPipe,
    IsoDatePipe,
    FrequencyTransformPipe,
    SnakeToStringPipe,
    PercentagePipe,
    StateStatusColorPipe,
    FilterFrequencyByPricesPipe,
    FilterAmountByPricesPipe,
    OrderByPipe,

    // Directives
    DownloadFileDirective,
    GoBackDirective,
  ]
})
export class SharedModule {}
