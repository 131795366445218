import { Injectable } from '@angular/core';
// import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject } from 'rxjs';

import { AppConfig } from '@app/app.config';
import { AuthService } from '@app/auth/auth.service';
import { Notifications } from '@shared/components/navbar/nav-bar.component';
import { LinkedAccountEventTypes, LinkedAccountWebsocketEvent } from '@shared/meta-data';

@Injectable({
  providedIn: 'root',
})
export class WebsocketConfigService {
  webSocket: WebSocket;
  notificationsSubject: BehaviorSubject<Array<Notifications>> = new BehaviorSubject([]);
  linkedTokenEventSubject: BehaviorSubject<LinkedAccountWebsocketEvent> = new BehaviorSubject(null);
  all_notifications = [];
  private readonly token: string;
  private readonly TOKEN_CHECK_INTERVAL = 1000 * 60 * 5; // 5 minutes
  private tokenRefreshInterval: any = null;
  
  constructor(
    private readonly authService: AuthService,
    // private readonly toastr: ToastrService,
  ) {
    this.connect();
  }

  getToken() {
    const { token, isValid } = this.authService.getTokenAndValidity();

    if(token && !isValid) {
      this.refreshTokenIfNeeded();
      return '';
    }

    return token || '';
  }

  private refreshTokenIfNeeded() {
   this.authService.startNewSession(false).subscribe(
    {
      next: () => {
        this.disconnect();

        this.connect();
      },
    }
   ); 
  }

  private setupTokenMonitoring() {
    // Clear any existing interval
    this.clearTokenMonitoring();

    this.tokenRefreshInterval = setInterval(() => {
      const { token, isValid } = this.authService.getTokenAndValidity();

      if(token && !isValid) {
        this.refreshTokenIfNeeded();
      }
    }, this.TOKEN_CHECK_INTERVAL);
  }

  private clearTokenMonitoring() {
    if(this.tokenRefreshInterval) {
      clearInterval(this.tokenRefreshInterval);
      this.tokenRefreshInterval = null;
    }
  }

  disconnect() {
    this.clearTokenMonitoring();

    if(this.webSocket) {
      this.webSocket.close();
      this.webSocket = null;
    }
  }

  connect() {
    // Ensure we close any existing connection
    this.disconnect();

      // Check if token is available before connecting
    const token = this.getToken();

    if (!token) {
      return;
    }

    // see https://stackoverflow.com/questions/4361173/http-headers-in-websockets-client-api
    this.webSocket = new WebSocket(`${AppConfig.WEBSOCKET_URL}?authorization=${this.getToken()}`);

    this.webSocketMessageHandler();
  }

  webSocketMessageHandler() {
    if (!this.webSocket) return;

    this.webSocket.onopen = () => {
      this.setupTokenMonitoring();
    };

    this.webSocket.onerror = (e) => {
      const { isValid } = this.authService.getTokenAndValidity();

      if (!isValid) {
        this.refreshTokenIfNeeded();
      }
    };

    this.webSocket.onclose = (e) => {
      this.clearTokenMonitoring();

      // Attempt reconnecting after one second
      if(e.code !== 1000) {
        const { isValid } = this.authService.getTokenAndValidity();

        if (!isValid) {
          this.refreshTokenIfNeeded();
        } else {
          setTimeout(() => this.connect(), 3000);
        }
      }
    };

    this.webSocket.onmessage = (messageEvent: MessageEvent) => {
      const message = JSON.parse(messageEvent.data);

      switch (message.type) {
        case LinkedAccountEventTypes.HISTORICAL_DATA_FETCH: 
          this.linkedTokenEventSubject.next(message);
          break;
        // case '/notifications/list':
        //   this.all_notifications = message.response;
        //   this.notificationsSubject.next(message.response);
        //   break;
        // case '/notifications/read':
        //   const read_notifications = message.response;
        //   const unread_notifications = this.all_notifications.filter(
        //     (notification) =>
        //       !read_notifications
        //         .map((n) => n.notification_id)
        //         .includes(notification.notification_id)
        //   );
        //   this.all_notifications = [
        //     ...unread_notifications,
        //     ...read_notifications,
        //   ];
        //   this.notificationsSubject.next(this.all_notifications);
        //   break;
        default:
          return;
      }
    };
  }

  listNotifications() {
    const listNotifications = {
      httpMethod: 'GET',
      path: '/notifications/list',
      headers: {
        authorization: this.getToken(),
      },
    };

    this.webSocket.send(JSON.stringify(listNotifications));
  }

  readNotifications(notification_ids: string[]) {
    const readNotifications = {
      httpMethod: 'POST',
      path: '/notifications/read',
      headers: {
        authorization: this.getToken(),
      },
      body: {
        notification_ids,
      },
    };

    this.webSocket.send(JSON.stringify(readNotifications));
  }

  deleteNotification(notification_id: string) {
    const deleteNotification = {
      httpMethod: 'DELETE',
      path: '/notifications/delete',
      headers: {
        authorization: this.getToken(),
      },
      body: {
        notification_id: notification_id,
      },
    };

    this.webSocket.send(JSON.stringify(deleteNotification));
  }
}
