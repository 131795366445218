export interface LinkedAccount {
  _metadata: string;
  _provider: string;
  _pull_count: string;
  _pull_from: string;
  _pull_to: string;
  _scopes: string;
  _store: string;
  _token: string;
  _type: string;
  _user_id: string;
  _validFrom: string;
  _validTo: string;
  _token_id: number;
}

export interface LinkedAccountEvent {
  business_profile_id: number;
  occured_at: string;
  provider: string;
  status: string;
  store: string;
  token_id: number;
  transaction_events_id: number;
  type: string;
}

export interface LinkedAccountWebsocketEvent {
  status: LinkedAccountEventStatus;
  type: LinkedAccountEventTypes;
  token_id: number;
}

export enum LinkedAccountEventStatus {
  NONE = 'NONE',
  STARTED = 'STARTED',
  COMPLETED = 'COMPLETED',
  INSTALLED = 'INSTALLED',
  DATA_PUSHED = 'DATA_PUSHED'
}

export enum APP_EVENTS {
  HISTORICAL_DATA_FETCH_STRIPE = 'HISTORICAL_DATA_FETCH_STRIPE',
  HISTORICAL_DATA_FETCH_SHOPIFY = 'HISTORICAL_DATA_FETCH_SHOPIFY',
};


export enum LinkedAccountEventTypes {
  HISTORICAL_DATA_FETCH = 'HISTORICAL_DATA_FETCH',
  PROVIDER_INTEGRATION = 'PROVIDER_INTEGRATION',
};